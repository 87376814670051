import React, { useState } from "react";
import { Button } from "../atoms/button";
import { Typography } from "@mui/material";
import { AddShoppingCart, CheckCircleOutline } from "@mui/icons-material";
import { useCart } from "../organisms/cart";

type AddToCartProps = {
  product: GatsbyProduct;
};

export const AddToCart = ({ product }: AddToCartProps) => {
  const [added, setAdded] = useState(false);
  const { add, canAdd } = useCart()!;

  const addToCart = async () => {
    setAdded(true);
    await add(product);
    setTimeout(() => setAdded(false), 1000);
  };
  return (
    <Button onClick={addToCart} disabled={added || !canAdd(product)}>
      <Typography
        component="span"
        sx={{
          display: "inline-flex",
          alignItems: "center",
          transition: "all 250ms",
          transform: `translateY(${added ? "-1rem" : "0"})`,
          opacity: added ? 0 : 1,
        }}
      >
        <AddShoppingCart fontSize="small" sx={{ mr: 0.5 }} />
        <Typography component="span">Ajouter</Typography>
      </Typography>
      <Typography
        component="span"
        sx={{
          display: "inline-flex",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transition: "all 250ms",
          transform: `translateX(-50%) translateY(${added ? "-50%" : "1rem"})`,
          opacity: added ? 1 : 0,
        }}
      >
        <CheckCircleOutline fontSize="small" sx={{ mr: 0.5 }} />
        <Typography component="span">Ajouté</Typography>
      </Typography>
    </Button>
  );
};
