import React, { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { AddToCart } from "./add-to-cart";
import {
  Colorize,
  InfoSharp,
  LocalDrink,
  LocationOn,
  Visibility,
} from "@mui/icons-material";
import { formatCapacity, formatPrice } from "../../api/shared/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { GatsbyProduct } from "../utils/shop";

type ProductCardProps = GatsbyProduct & {
  domain: string;
  shopId: string;
};

export const ProductCard = (props: ProductCardProps) => {
  const {
    sku,
    ean,
    name,
    vintage,
    image,
    title,
    grape,
    alcohol,
    color,
    capacity,
    location,
    price,
    packing,
    datasheet,
    domain,
    inStock,
    limit = 0,
  } = props;
  const media = useMemo(() => image, [image]);
  return (
    <Card
      itemScope
      itemType="https://schema.org/Product"
      itemRef="shop-brand"
      elevation={0}
      sx={{
        position: "relative",
        ":hover": {
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.1)",
        },
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "12rem",
          ".gatsby-image-wrapper": {
            display: "block",
            height: "12rem",
            ":after": {
              content: "''",
              backgroundImage: "url('/out-of-stock.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              width: "100%",
              height: "100%",
              display: inStock ? "none" : "block",
              position: "absolute",
              top: 0,
              left: 0,
            },
          },
          picture: {
            opacity: inStock ? 1 : 0.5,
          },
          img: {
            borderTopLeftRadius: "0.25rem",
            borderTopRightRadius: "0.25rem",
            filter: inStock ? undefined : "grayscale()",
          },
        }}
      >
        {media?.childImageSharp && (
          <GatsbyImage
            itemProp="image"
            image={media.childImageSharp.big}
            alt={name}
          />
        )}
        {limit > 0 && (
          <Chip
            size="small"
            icon={<ErrorOutlineOutlinedIcon />}
            label={`${limit} bouteilles max / commande`}
            sx={{
              borderRadius: "0.5rem",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "1rem",
              backgroundColor: "background.default",
              color: "text.primary",
              ".MuiChip-icon": {
                color: "primary.main",
              },
            }}
          />
        )}
      </Box>
      <CardContent
        sx={{ padding: 1, flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ py: 1, position: "relative" }}>
          <meta itemProp="sku" content={sku} />
          <meta itemProp="gtin" content={ean} />
          <meta
            itemProp="name"
            content={`${name}${
              (vintage || "").length > 0 ? ` ${vintage}` : ""
            }`}
          />
          <Typography component="h2">
            <Typography sx={{ fontWeight: "bold" }} component="span">
              {name}
            </Typography>{" "}
            <Typography component="span" color="text.secondary">
              {vintage}
            </Typography>
          </Typography>
          {datasheet && (
            <Button
              target="_blank"
              rel="noopener"
              startIcon={<InfoSharp fontSize="small" color="primary" />}
              variant="text"
              size="small"
              href={datasheet}
              sx={{
                position: "absolute",
                top: "0.5rem",
                right: "-4px",
                textTransform: "lowercase",
              }}
            >
              fiche
            </Button>
          )}
        </Box>
        <Box
          sx={{
            borderWidth: "1px 0",
            borderStyle: "solid",
            borderColor: "background.default",
            py: 1,
            flex: 1,
          }}
        >
          <Typography itemProp="category" variant="body2">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {grape.join(" / ")}
          </Typography>
        </Box>
        <Grid
          container
          spacing={1}
          sx={{
            py: 1,
            borderWidth: "0 0 1px",
            borderStyle: "solid",
            borderColor: "background.default",
            textTransform: "capitalize",
          }}
        >
          <Grid item xs={6} sx={{ display: "inline-flex" }}>
            <Visibility
              fontSize="small"
              color="primary"
              sx={{ width: "1.5rem", mr: "0.25rem" }}
            />
            <Typography component="span" variant="body2">
              {color}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "inline-flex" }}>
            <LocationOn
              fontSize="small"
              color="primary"
              sx={{ width: "1.5rem", mr: "0.25rem" }}
            />
            <Typography component="span" variant="body2">
              {location}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "inline-flex" }}>
            <LocalDrink
              fontSize="small"
              color="primary"
              sx={{ width: "1.5rem", mr: "0.25rem" }}
            />
            <Typography
              sx={{ textTransform: "none" }}
              component="span"
              variant="body2"
            >
              {formatCapacity(capacity)}
              {packing > 1 && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  &nbsp;x&nbsp;{packing}
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "inline-flex" }}>
            <Colorize
              fontSize="small"
              color="primary"
              sx={{ width: "1.5rem", mr: "0.25rem" }}
            />
            <Typography component="span" variant="body2">
              {alcohol}%
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container alignItems="center" sx={{ height: "2.5rem" }}>
          <Grid
            itemScope
            itemProp="offers"
            itemType="https://schema.org/Offer"
            flexGrow={1}
            item
          >
            <meta itemProp="price" content={Number(price / 100).toFixed(2)} />
            <meta itemProp="priceCurrency" content="EUR" />
            <link
              itemProp="availability"
              href={`https://schema.org/${inStock ? "InStock" : "OutOfStock"}`}
            />
            <link itemProp="url" href={`https://${domain}/#${sku}`} />
            <Typography component="span" fontWeight="bold">
              {formatPrice(price)}
            </Typography>
            <Typography component="span" variant="body2" color="text.secondary">
              &nbsp;/ {packing > 1 ? `${packing} bouteilles` : "bouteille"}
            </Typography>
          </Grid>
          <Grid flexGrow={1} item>
            {inStock && <AddToCart product={props} />}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
